import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="City Stories" />
    <h1>City Stories</h1>
    <p>Hier kommt nen Infotext über das Projekt hin.</p>
  </Layout>
)

export const query = graphql`
  query 
  { 
    allMdx {
      nodes {
        frontmatter {
          title
        }
        id
        body
        slug
      }
    }
  }
`

export default IndexPage
